<template>
  <v-row justify="center">
    <v-dialog
      v-model="propModel"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Agregar nuevo formulario</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre del Formulario*"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  label="Campo*"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  :items="['Texto', 'Numerico', 'Booleano']"
                  label="Tipo de campo*"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="1"
              >
                    <v-btn
                        color="error"
                        class="ma-2"
                        outlined
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                class="text-center"
              >
                <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading"
                outlined
                color="success"
                @click="loader = 'loading'"
                >
                Agregar campo
                </v-btn>
              </v-col>
              
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_form = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="success"
            @click="dialog_form = false"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
        props: {
            value: Boolean, 
            eitem: Object,
        },
        data() {
            return {
                editedItem: this.eitem,
            }
        },
        computed: {
          propModel: {
            get () {
 return this.value 
},
            set ( value ) {
 this.$emit( 'input', value ) 
},
          },
        },
        methods: {
            save() {
                // perform save
                this.$emit( 'input', false );
            },
            close() {
                this.$emit( 'input', false );
            },
        },
    }
</script>
