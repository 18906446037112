<template>
    <v-row>
        <v-col cols="3" class="ml-3">
  <v-card
    class="mx-auto"
  >
  <v-toolbar
          color="primary"
          light
          extended
        >
          <v-toolbar-title class="white--text">
            Proceso de expansion MallLikeU
          </v-toolbar-title>
          <template v-slot:extension>
            <v-btn
              fab
              color="grey lighten-1"
              bottom
              left
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-toolbar>
        
    <v-list
      subheader
      two-line
      class="mt-5"
    >
      <v-subheader inset>Formularios</v-subheader>
      <v-list-item
        v-for="folder in folders"
        :key="folder.title"
      >
        <v-list-item-avatar>
          <v-icon
            class="grey lighten-1"
            dark
          >
            mdi-clipboard-text
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="folder.title"/>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset/>

      <v-subheader inset>Notificaciones</v-subheader>

      <v-list-item
        v-for="file in files"
        :key="file.title"
      >
        <v-list-item-avatar>
          <v-icon
            :class="file.color"
            dark
            v-text="file.icon"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="file.title"/>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    
        <v-dialog
          v-model="dialog"
          max-width="300px"
        >
          <v-card
                    class="mx-auto"
                    tile
                  >
                    <v-list dense>
                      <v-subheader>Seleccione el objeto a crear</v-subheader>
                      <v-list-item-group
                        color="primary"
                      >
                        <v-list-item
                          v-for="(item, i) in items_object"
                          :key="i"
                          @click="openDialog"
                        >
                          <v-list-item-icon>
                            <v-icon v-text="item.icon"/>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.text"/>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
        </v-dialog>
        <create-form v-model="dialog_form" />
        <create-document v-model="dialog_document" />
  </v-card>

        </v-col>
        <v-col cols="8">
            <v-btn color="primary" 
            @click="$refs.chart.add({id: +new Date(), x: 10, y: 10, name: 'Tipo', approvers: [{ id: 1, name: 'Proceso' }], type: 'operation', approvers: []})">
                Agregar
            </v-btn>
            <v-btn type="button" @click="$refs.chart.remove()">
                Eliminar
            </v-btn>
            <flowchart 
              :nodes="nodes" 
              :connections="connections" 
              @dblclick="handleDblClick"
              ref="chart"/>
            <node-dialog
              :visible.sync="nodeDialogVisible"
              :node.sync="nodeForm.target"
            />
        </v-col>
    </v-row>
    
    
</template>
<script>
  import CreateForm from './Partials/CreateForm'
  import CreateDocument from './Partials/CreateDocument'

  export default {
    components: { 
      CreateForm, 
      CreateDocument 
    },
    data: () => ( {
      nodeDialogVisible: false,
      nodeForm: { target: null },
      dialog: false,
      dialog_form: false,
      dialog_document: false,
      nodes: [
        { 
          id: 1, 
          x: 50, 
          y: 270, 
          name: 'Inicio', 
          type: 'start',
          withd: 60,
          height: 60
        },        
        {
          id: 2,
          x: 250,
          y: 270,
          name: 'Formularios',
          type: 'operation',
          approvers: [ { id: 1, name: 'Encuesta' } ],
          width: 120,
          height: 60,
        },
        {
          id: 3,
          x: 400,
          y: 270,
          name: 'Notificaciones',
          type: 'operation',
          approvers: [ { id: 2, name: 'Email de bienvenida 1' } ],
          width: 180,
          height: 60,
        },
        {
          id: 4,
          x: 600,
          y: 270,
          name: 'Notificaciones',
          type: 'operation',
          approvers: [ { id: 2, name: 'Email de bienvenida 2' } ],
          width: 180,
          height: 60,
        },
        {
          id: 5, 
          x: 50, 
          y: 350,           
          name: 'Fin', 
          type: 'end', 
          withd: 60,
          height: 60
        },

      ],
      connections: [
        {
          source: { id: 1, position: 'right' },
          destination: { id: 2, position: 'left' },
          id: 1,
          type: 'pass',
        },
        {
          source: { id: 2, position: 'right' },
          destination: { id: 3, position: 'left' },
          id: 2,
          type: 'pass',
        },
        {
          source: { id: 3, position: 'right' },
          destination: { id: 4, position: 'left' },
          id: 3,
          type: 'pass',
        },
        {
          source: { id: 4, position: 'right' },
          destination: { id: 5, position: 'left' },
          id: 3,
          type: 'pass',
        },
      ],
      files: [
        {
          color: 'blue',
          icon: 'mdi-email',
          title: 'Email de bienvenida 1',
        },
        {
          color: 'blue',
          icon: 'mdi-email',
          title: 'Email de bienvenida 2',
        },
      ],
      folders: [
        {
          title: 'Encuesta',
        },
      ],
      items_object: [
        { text: 'Formulario', icon: 'mdi-format-list-checkbox' },
        { text: 'Notificacion', icon: 'mdi-bell' },
        { text: 'Documento', icon: 'mdi-file' },
        { text: 'Verificacion', icon: 'mdi-check-circle-outline' },
        { text: 'Firma de documento', icon: 'mdi-pencil-outline' },
      ],
    } ),
    methods: {
      handleDblClick( position ) {
        this.$refs.chart.add( {
          id: +new Date(),
          x: position.x,
          y: position.y,
          name: 'New',
          type: 'operation',
          approvers: [],
        } );
      },
      openDialog () {
        this.dialog = false
        this.dialog_form = true
        this.dialog_document = true
      }
    },
  }
</script>
