var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.propModel),callback:function ($$v) {_vm.propModel=$$v},expression:"propModel"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Agregar nuevo formulario")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre del Formulario*","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Campo*","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":['Texto', 'Numerico', 'Booleano'],"label":"Tipo de campo*","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","outlined":""}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"outlined":"","color":"success"},on:{"click":function($event){_vm.loader = 'loading'}}},[_vm._v(" Agregar campo ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_form = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.$refs.chart.add({
							id: +new Date(),
							x: 10,
							y: 10,
							name: 'Tipo',
							approvers: [{ id: 1, name: 'Proceso' }],
							type: 'operation',
							approvers: [],
						})}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }