<template>
	<div class="vuse-content-wrapper">
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<template>
						<v-stepper v-model="e6" vertical height="250">
							<v-stepper-step :complete="e6 > 1" editable step="1">
								Seleccione el proceso
							</v-stepper-step>

							<v-stepper-content step="1">
								<select-process />

								<v-btn color="primary" @click="e6 = 2"> Continuar </v-btn>
								<v-btn text> Atras </v-btn>
							</v-stepper-content>

							<v-stepper-step :complete="e6 > 2" editable step="2">
								Diseñar el proceso
							</v-stepper-step>

							<v-stepper-content step="2">
								<design-process />

								<v-btn color="primary" @click="e6 = 3"> Continuar </v-btn>
								<v-btn text> Atras </v-btn>
							</v-stepper-content>

							<v-stepper-step :complete="e6 > 3" editable step="3">
								Documentacion
							</v-stepper-step>

							<v-stepper-content step="3">
								<v-btn color="primary" @click="e6 = 4"> Continuar </v-btn>
								<v-btn text> Atras </v-btn>
							</v-stepper-content>
						</v-stepper>
					</template>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
	import SelectProcess from '@/components/CreateWorkFlow/SelectProcess'
	import DesignProcess from '@/components/CreateWorkFlow/DesignProcess'

	export default {
		components: {
			SelectProcess,
			DesignProcess,
		},
		data() {
			return {
				e6: 1,
			}
		},
	}
</script>
