<template>
  <v-container>
    <v-row>
        <v-col cols="6">
            <v-row justify="space-around">
                <v-card width="400">
                    <v-img
                    height="200px"
                    src="/bus.jpg"
                    />
                    <v-card-title>Proceso de expansion<br/>MallLikeU</v-card-title>
                    <v-card-actions>
                    <v-btn
                        outlined
                        rounded
                        text
                    >
                        Empezar
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                      icon
                      @click="show = !show"
                    >
                      <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                      <div v-show="show">
                        <v-divider/>
                        <div class="font-weight-bold ml-8 mb-2">
                            Inicio
                        </div>
                        <v-timeline
                            align-top
                            dense
                        >
                            <v-timeline-item
                            v-for="message in messages"
                            :key="message.time"
                            :color="message.color"
                            small
                            >
                            <div>
                                <div class="font-weight-normal">
                                <strong>{{ message.from }}</strong> {{ message.time }}
                                </div>
                                <div>{{ message.message }}</div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        <div class="font-weight-bold ml-8 mb-2">
                            &nbsp;&nbsp;&nbsp;Fin
                        </div>
                      </div>
                    </v-expand-transition>
                </v-card>
                </v-row>    
        </v-col>
        <v-col cols="6">
            <v-card width="400">
            <v-img
            height="200px"
            src="https://res.cloudinary.com/monday-blogs/w_1024,h_1024,c_fit/fl_lossy,f_auto,q_auto/wp-blog/2020/12/workflow-diagram-2-1.jpg"
            />
            <v-card-title>Crear flujo de expansion<br/>personalizado</v-card-title>
                <v-card-actions>
                <v-btn
                    outlined
                    rounded
                    text
                >
                    Empezar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    
  </v-container>
</template>
<script>
  export default {
    data: () => ( {
      show: false,
      messages: [
        {
          from: 'Encuesta',
          message: 'Formulario',
          color: 'deep-purple lighten-1',
        },
        {
          from: 'Colas por territorio',
          message: 'Tarea automatica',
          color: 'green',
        },
        {
          from: 'Planilla de solicitud de franquicias',
          message: 'Formulario',
          color: 'deep-purple lighten-1',
        },
      ],
    } ),
  }
</script>
